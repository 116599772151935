#wavesurfer-zoom-buttons {
  position: relative;
  left: 23px;
  width: 40px;
}

#audio_zoom_plus_box,
#audio_zoom_minus_box {
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  margin: 10px;
  position: relative;
  border-color: #3fb8f7;
  cursor: pointer;

  &:hover {
    background: #3fb8f7;
    & > svg > path {
      fill: white;
    }
  }
}

.zoom-icon {
  margin-left: 18px;
}

.audio__buttons-container {
  text-align: center;
  position: relative;
  left: -4px;
}

.svg-path {
  fill: #3fb8f7;
}

.play-volume-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 43rem;
}

.wavesurfer-volume-slider {
  display: flex;
  -webkit-appearance: none;
  width: 15rem;
  height: 5px;
  border-radius: 8px;
  background: linear-gradient(
    to right,
    #3fb8f7 0%,
    #3fb8f7 100%,
    rgb(189, 188, 188) 100%,
    rgb(189, 188, 188) 100%
  );
}

.volume-container {
  display: flex;
  align-items: center;
}

.volume-img {
  height: 20px;
  width: 20px;
  margin: 0 0 0 5px;
}

.wavesurfer-volume-slider:focus {
  outline: none;
}

.wavesurfer-volume-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background: rgb(241, 240, 240);
  cursor: pointer;
}

.wavesurfer-volume-slider::-moz-focus-outer {
  border: 0;
}

.wavesurfer-volume-slider::-moz-range-thumb {
  border: 1px solid;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background: rgb(250, 246, 246);
  cursor: pointer;
}

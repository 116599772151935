.section-group {
  margin-bottom: 12px;
}

.dropdown-example .dropdown-menu {
  clear: left;
  position: static;
}

.dropdown-filter > .dropdown-menu {
  padding: 0;
}
.dropdown-menu-filter {
  margin: 8px;
}
.dropdown-menu .checkbox > label {
  width: 100%;
}
input[type="text"]:focus,
input[type="number"]:focus,
textarea:focus,
.input-focus {
  border-color: #0096cc;
  transition: all 0.2s linear 0s;
  outline: none;
}
.input-icon-group {
  position: relative;
}
.input-icon-group > .form-control {
  padding-left: 30px;
  padding-right: 36px;
  height: 4.5rem;
  font-size: 1.4rem;
}
.input-icon-group > .form-control:focus + .input-icon-label > i,
.input-icon-group > .input-icon-label .focus {
  transition: all 0.2s linear 0s;
  color: #0096cc;
}
.input-icon-label {
  position: absolute;
  left: 9px;
  top: 50%;
  transform: translateY(25%);
  margin-top: -10px;
  color: #666;
  font-size: 13px;
}
.form-control {
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 12px;
  font-size: 13px;
  color: #222;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 3px;
}
